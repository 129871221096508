.__Tender__ {
  .innerContainer {
    margin: 20px auto !important;
    max-width: 100%;
  }
  .tenderHeader {
    justify-content: center;
    display: flex;
    counter-reset: list-number;
    margin: 10px 0;
  }
  .tenderHeader li {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    color: #d5d7e3;
    position: relative;
    padding-right: 5px;
    border-bottom: 3px solid #7e84a3;
  }

  .tenderHeader li:before {
    counter-increment: list-number;
    content: counter(list-number);
    margin: 5px;
    margin-top: 0;
    width: 25px;
    height: 25px;
    font-size: 15px;
    background-color: #d5d7e3;
    border-radius: 50%;
    color: #fff;
  }
  .tenderHeader li.active {
    color: #2B7776;
    border-bottom: 3px solid #2B7776;
    cursor : pointer;
  }
  /* .tenderHeader li.active:after{ */
  /* content: ""; */
  /* background: #2B7776; */
  /* height: 2px; */
  /* position: absolute; */
  /* left: -15px; */
  /* width: 198px; */
  /* top: 100%; */
  /* } */
  /* .tenderHeader li:nth-child(2).active:after{ */
  /* left: -32px; */
  /* width: 220px; */
  /* } */
  /* .tenderHeader li:nth-child(7).active:after{ */
  /* left: -16px; */
  /* width: 173px; */
  /* } */
  .tenderHeader li.active:before {
    color: #fff;
    background: #2B7776;
  }
  .tenderHeader li.active.visited::before {
    background: url("../../assets/checkbox.svg") center center no-repeat;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
    background-size: 100%;
  }

  h3.subheading {
    font-family: Poppins;
    width: 30px;
    overflow: visible;
    border-bottom: 3px solid black;
    white-space: nowrap;
  }

  .hedding h2 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: 0.09px;
    text-align: center;
    color: #3f3f41;
    position: relative;
    padding-bottom: 5px;
  }
  .hedding h2:after {
    content: "";
    height: 3px;
    background: #3f3f41;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 50%;
    max-width: 100px;
    transform: translateX(-50%);
  }

  .parentBlock {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  .textarea01 {
    width: 664.5px;
    height: 253px;
    margin: 0 0 30px 14.5px;
    border-radius: 5px;
    border: solid 1px #d5d7e3;
    background-color: #ffffff;
    resize: none;
  }

  textarea:focus {
    outline: 0;
  }
  .preTender_asset .ant-select-selector {
    max-width: 300px;
    height: 43px;
    border-radius: 9px;
    border: 1px solid #d5d7e3;
    background-color: #fff;
    
    
  }
  .__AssetDetails__ {
    &.preTenderAsset {
      .parentBlock2 label {
        width: 100%;
      }
      .ant-form-item {
        margin-bottom: 0;
      }
      .btn {
        border-radius: 25px !important;
        height: 38px;
      }
    }
    .ant-form-item {
      margin-bottom: 20px;
    }
    label {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    margin-top: 7px;
    }
    input {
      max-width: 300px;
      height: 43px;
      border-radius: 9px;
      border: 1px solid #d5d7e3;
      background-color: #fff;
    }
}
.addAssets {
  margin-top: 60px;
}
.header_summary {
  background-color: #fefefead;
  padding: 10px;
  border-radius: 10px;
  font-size: 10px;
  /* text-decoration: inherit; */
  font-style: italic;
  margin-bottom: 10px;
  .org_names {
    max-height: 80px;
    padding-right: 10px;
    overflow: auto;
  }
}
}

@primary-color: #57b8ff;