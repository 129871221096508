.__TenderCreate_TenderDetails__{
  max-width: 900px;
  margin: 0 auto;
  .visibility{
    width:50%;
  }
  input+label{
    width:100%;
    &::before,&::after{
      top:8px !important;
    }
  }
  .formGroup {
    justify-content: flex-start;
  }
}

.parentBlock2 {
  max-width: 80%;
  margin: 0 auto;
}
.parentBlock2 label {
  width: 20%;
}
.parentBlock2 .formGroup {
  display: flex;
  margin-bottom: 20px;
}
.formGroup .textarea01 {
  height: 150px;
  margin: 0;
  width: 80%;
  padding: 15px;
}
.formFiled {
  width: 80%;
  height: 43px;
  border-radius: 9px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  padding: 15px;
}


@primary-color: #57b8ff;