.companyAddModal .pageOverlay{
    width: 100%;
    height: 100vh;
    padding: 0 0 19px;
    background-color: rgb(63, 63, 65, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}
.modalParent{
    max-width: 600px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}
.companyAddModal .modalOuterMain{
    max-width: 600px;
    /* top: 50%; */
    box-shadow: 0 1px 3px 0 #2B7776;
    /* left: 50%;
    position: absolute;
    transform: translate(-50%, -50%); */
    width: 100%;
    background:  #e6e9f4;
    height: 600px;
    overflow-y: auto;
}
.companyAddModal .modalOuter2{
    margin: 20px;
    padding: 20px;
    border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
  background-color: #ffffff;
}
.companyAddModal .modalOuter2 .button2{
    max-width: 222px;
    margin-top: 20px;
}
.companyAddModal .modalOuter2 .button2 span{padding-left: 10px;}
.companyAddModal .errorMessage{margin-left: 17px;}
.companyAddModal .closeBtn2 svg{width: 35px;}
.companyAddModal .closeBtn2 svg path{fill:#fff;}
.companyAddModal .closeBtn2 svg:hover{opacity: 0.8;}
.companyAddModal .inputFieldGroup .inputFiled{
    width: 270px;
    height: 45px;
    border-radius: 5px;
    border: solid 0.5px #d5d7e3;
    background-color: transparent;
    padding: 5px 15px;
    font-family: Poppins;
    font-size: 16px;
    float: right;
    color: #3f3f41;
}
.companyAddModal .inputFieldGroup .inputFiled:read-only {
    background-color: #e6e9f4;
}
.companyAddModal input:focus{outline: 0;}
.companyAddModal .inputFieldGroup{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.companyAddModal .inputFieldGroup label{
    font-family: Poppins;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.15;
    letter-spacing: normal;
    text-align: left;
    color: #3f3f41;
    padding: 0 30px 0 0;
    max-width: 250px;
    display: block;
    line-height: 19px;
    
}
.companyAddModal .closeBtn{
    background: url("../../assets/close-btn.svg") left center no-repeat;
    width: 35px;
    height: 39px;
    display: block;
    background-size: 100%;
    text-indent: -9999px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 10px;
}
.companyAddModal .closeBtn2{
    position: absolute;
    left: 103%;
    top: 0;
    width: 35px;
    height: 39px;
    display: block;
    cursor: pointer;
}
.companyAddModal .errorMessage {
    color: red;
}
.emailGroup label {
    width: 35%;
}
.emailGroup input {
    margin-bottom: 15px;
}

