.__TenderCard__ {
  margin-bottom: 10px;
  .err {
    width: 100%;
    text-align: center;
  }

  .cardcontainer {
    .card-row {
      width: 100%;
      text-align: left;
      // word-break: break-word;
    }
    .title {
      padding: 0 10px;
      width: 100%;
    }
    li.four-column,
    .content > div.four-column {
      width: 25%;
      padding: 0 5px;
      word-break: break-all;
    }
    li.four-column-action,
    .content > div.four-column-action {
      width: 31%;
      padding: 0 5px;
      word-break: break-all;
    }
    .four-column-bids {
      width: 12%;
    }
    .three-column {
      width: 33%;
    }
    .title {
      display: flex;
    }

    .title li,
    .content > div {
      font-family: Poppins;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      color: black;
    }

    .content {
      display: flex;
      padding: 10px;
      border-radius: 8px;
      background-color: #f5f6fa;
      margin: 0 0 8px 0;
      align-items: center;
    }
    .starIcon {
      display: inline-block;
      width: 15px;
      height: 18px;
      margin-right: 10px;
      background: url("../../assets/starts.png") left center no-repeat;
    }
  }
}

@primary-color: #57b8ff;