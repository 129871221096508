.__AwardCompletion__ {
  .textBlock {
    align-items: center;
  }
  .date-btn{
    margin: 5px 10px;
  }
  .completion_date {
    margin: 0;
  }
  }

@primary-color: #57b8ff;