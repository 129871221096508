.pageOverlay{
    width: 100%;
    height: 100vh;
    padding: 0 0 19px;
    background-color: rgb(63, 63, 65, 0.8);
    position: fixed;
    top: 0;
    left: 0;
}
.modalOuter{
    max-width: 600px;
    /* height: 162px; */
    top: 50%;
    padding: 50px 80px 50px 80px;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 #2B7776;
    background-color: #ffffff;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 100%;
}
.inputFieldGroup .inputFiled{
    width: 270px;
    height: 45px;
    border-radius: 5px;
    border: solid 0.5px #d5d7e3;
    background-color: transparent;
    padding: 5px 15px;
    font-family: Poppins;
    font-size: 16px;
    float: right;
}
.inputFieldGroup .inputFiled:read-only {
    background-color: #e6e9f4;
}
input:focus{outline: 0;}
.inputFieldGroup label{
    font-family: Poppins;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.15;
    letter-spacing: normal;
    text-align: center;
    color: #3f3f41;
    padding: 0 30px 0 0;
}
.closeBtn{
    background: url("../../assets/close-btn.svg") left center no-repeat;
    width: 35px;
    height: 39px;
    display: block;
    background-size: 100%;
    text-indent: -9999px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 10px;
}
.errorMessage {
    color: red;
}