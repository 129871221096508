.parentBlock2.parentBlock3 {
  max-width: 50%;
  margin: 0 auto;
}
.parentBlock2 label {
  width: 50%;
  white-space: break-spaces;
}
.parentBlock2 .formGroup {
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}
.parentBlock2 .formGroup span {
  padding-left: 10px;
}
.formGroup .textarea01 {
  height: 150px;
  margin: 0;
  width: 50%;
  padding: 15px;
}
.flexContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
}
.formFiled {
  width: 50%;
  height: 43px;
  border-radius: 9px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  padding: 15px;
}
.formFiled.days {
  width: 85px;
}
.selectDate {
  position: relative;
  width: 50%;
}
.selectDate .calendraIcon {
  background: url("../../../assets/calendar.svg") left center no-repeat;
  position: absolute;
  right: 10px;
  top: 7px;
  width: 25px;
  height: 25px;
  background-size: 100%;
}
.selectDate .formFiled {
  width: 100%;
  padding: 0 45px 0 10px;
}
.parentBlock2 .formGroup .fillterTitle {
  padding-left: 0;
  font-size: 20px;
  font-weight: 600;
}
.selectDate .searchIcon {
  background: url("../../../assets/search.svg") left center no-repeat;
  position: absolute;
  right: 7px;
  top: 9px;
  width: 25px;
  height: 25px;
  background-size: 75%;
  cursor: pointer;
}
.selectDate .searchIcon:hover {
  opacity: 0.8;
}
.cardBlock {
  background: #fff;
  padding: 10px;
}
.companyWrapper .radioBtn{margin-right: 7px;
    margin-left: 10px;}
    .companyWrapper .radioBtn .ant-checkbox-inner {
      width: 20px;
      height: 20px;
    }

  .companyWrapper  input[type="radio"]:checked + label,
.companyWrapper input[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 45px;
    cursor: pointer;
    line-height: 36px;
    display: inline-block;
    color: #666;
    top: -15px;
}
.companyWrapper input[type="radio"]:checked + label:before,
.companyWrapper input[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    /* border: 1px solid #ddd; */
    border-radius: 100%;
    background: url("../../../assets/checkbox-unchecked.svg") left top no-repeat;
}
.companyWrapper input[type="radio"]:checked + label:after,
.companyWrapper input[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 22px;
    height: 22px;
    background: url("../../../assets/checkbox.svg") left top no-repeat;
    position: absolute;
    background-size: 100%;
    top: 0;
    left: 0;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.fixed_height{
    width: 256px;
    table-layout: fixed;
    border-collapse: collapse;
}
.fixed_height tbody{
  display:block;
  overflow:auto;
  height:212px;
  width:100%;
}
.fixed_height thead tr{
  display:block;
}

.fixed_height th:first-child, .fixed_height td:first-child {
  text-align: left;
  width: 176px;
}

.fixed_height th:last-child, .fixed_height td:last-child {
  text-align: left;
  width: 80px;
}
