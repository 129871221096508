.__AttachFile__ .filecard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 0;
  border: 1px solid black;
  border-radius: 10px;
  width: 100%;
}

.__AttachFile__ .filecard h3 {
  margin: 5px;
}

.parentBlock2 {
  max-width: 80%;
  margin: 0 auto;
}
.parentBlock2 label {
  width: 20%;
}
.parentBlock2 .formGroup {
  display: flex;
  margin-bottom: 20px;
}
.formGroup .textarea01 {
  height: 150px;
  margin: 0;
  width: 80%;
  padding: 15px;
}
.formFiled {
  width: 80%;
  height: 43px;
  border-radius: 9px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  padding: 15px;
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 25px;
  border: 2px dashed #000;
  border-radius: 3px;
  transition: 0.2s;
  justify-content: center;
}
.file-drop-area.is-active {
  background-color: rgba(255, 255, 255, 0.05);
}

.fake-btn {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-msg {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.file-input:focus {
  outline: none;
}
.file-drop-area svg {
  height: 30px;
}
