.info_icon {
    font-size: 14px;
    margin-left: 2px;
    color: #7e84a3;
    cursor: pointer;
}
.tool_tip {
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-style: italic;
    border-radius: 10px;
    color: #7e84a3;
    margin: 5px 0;
    display: block;
    font-weight: 500;
}