.__ConfirmTender__ {
  .confirm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 50px;
  }
}

@primary-color: #57b8ff;