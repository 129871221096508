@import "~antd/lib/style/index";

@primary-color: #2B7776;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 1439px) {
  .ant-table-thead > tr > th {
    font-size: 12px;
  }
  .ant-table-tbody > tr > td{
    font-size: 11px;
  }
}

@media (max-width: 1440px) {
  .ant-table-thead > tr > th {
    font-size: 13px;
  }
  .ant-table-tbody > tr > td{
    font-size: 12px;
  }
  .dataBlock .dataTitle.list2 li, .dataBlock .dataContent.list2 li {
    font-size: 11px;
  }
}

@media (max-width: 1023px) {
  .ant-table-thead > tr > th, .ant-table-column-title {
    font-size: 11px;
  }
  .ant-table-tbody > tr > td{
    font-size: 10px;
  }
}

#supplier_form_city_id {
  display: block;
}
@primary-color: #57b8ff;