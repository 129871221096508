.__EuroInput__inputcontainer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  input {
    padding-right: 25px;
    appearance: none;
    -moz-appearance: textfield;
    text-align: right;
  }

  &::after {
    position: absolute;
    top: 12px;
    right: 10px;
    font-weight: bold;
    content: "€";
  }
}

@primary-color: #57b8ff;