.text-gray{
    color: #d5d7e3;
}

.display-2{
    font-size: 50px;
    font-weight: 700;
}

.mt-15{
    margin-top: 15px;
}

.mt-20{
    margin-top: 20px !important;
}

.justify-content-between{
    justify-content: space-between;
}

.height-100{
    min-height: 100px;
}

.height-150{
    min-height: 150px;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

.col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}

.card {
    padding: 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border-radius: 15px;
}

.card h2:after {
    background: unset;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .mb-3{
    margin-top: 15px;
  }

  .span-card-btn{
    border: 1px solid #2B7776;
    padding: 0px 10px;
    height: 28px;
    margin-right: 10px;
  }

  .width-30p{
    width: 30%;
  }

  .width-70p{
    width: 70%;
  }

  .width-40p{
    width: 40%;
  }

  .width-60p{
    width: 60%;
  }

  .style-1{
    font-size: 50px;
    margin-top: 40px;
    margin-bottom: 0px;
  }
@primary-color: #57b8ff;