.__FileUpload__ {
  .filecard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 10px 0;
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    h3 {
      margin: 5px;
    }
    .ant-form-item-label {
      text-align: left;
    }
  }
  .infoText {
    font-style: italic;
    text-align: center;
  }
}

@primary-color: #57b8ff;