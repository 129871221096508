@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import "~antd/dist/antd.less";
@import './theme.less';

@primary-color: #2B7776;

.ant-btn-primary:hover, .ant-btn-primary:focus {
  background: #339692;
  border-color: #2B7776;
}

.ant-btn:hover, .ant-btn:focus {
  color: #339692;
  background: #fff;
  border-color: #2B7776;
}
.ant-pagination-item-active {
  border-color: #2B7776;
  a {
    color: #2B7776;
  }
} 

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #2B7776;
  background-color: #2B7776;
}

.ant-form-item-label {
  text-transform: capitalize;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #f4f7fc;
}
* {
  box-sizing: border-box;
}
.buttonBlock {
  display: flex;
  justify-content: space-between;
}
.btn {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.08px;
  text-align: center;
  color: #ffffff;
  /* height: 29px; */
  margin: 25px 0 0 0;
  padding: 5px 18px 4px 17px;
  border-radius: 8px;
  background-color: #2B7776;
  cursor: pointer;
  text-transform: capitalize;
}
// .btn.cancelBtn {
//   background: #bfc5e2;
// }
.button1:hover {
  opacity: 0.8;
}

.comments {
  padding: 10px;
  background: #e6e9f4;
  border: none;
  border-radius: 10px;
  outline: none;
}

.width-10 {
  width: 10% !important;
  text-align: center;
}
.width-15 {
  width: 15% !important;
}
.width-20 {
  width: 20% !important;
  text-align: center;
}
.width-50 {
  width: 50% !important;
}
.width-70 {
  width: 70% !important;
}

.mainContainer {
  margin: 40px 70px 40px 70px;
  padding: 21px 0 20px;
  border-radius: 5px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.innerContainer {
  max-width: 90%;
  padding: 20px;
  border-radius: 12px;
  background-color: #f2f3f8;
  margin: 0 auto;
}

.pdf-header{
  background: url("./assets/Hissitito logo.png") left center no-repeat;
  width: 15%;
  height: 70px;
  // display: inline-block;
  background-size: 100%;
  float: right;
}
.pdf_header{
  // display: none !important;
}
.pdf-footer{
  background: url("./assets/footer.png") left center no-repeat;
  width: 100%;
  height: 70px;
  display: inline-block;
  background-size: 100%;
  // display: none;
  margin-top: 20px;
}

.editIcon {
  background: url("./assets/edit-icon.png") left center no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;
  background-size: 100%;
  text-indent: -9999px;
  cursor: pointer;
  margin-left: 25px;
}
.deleteIcon {
  background: url("./assets/delete-icon.png") left center no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: 100%;
  text-indent: -9999px;
  cursor: pointer;
  margin-left: 25px;
}

.btn-primary {
  padding: 4px 10px 3px 10px;
  border-radius: 8px;
  background-color: #D4ECEB;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  width: auto;
  display: inline-block;
  cursor: pointer;
}

.btn-secondary {
  padding: 4px 10px 3px 10px;
  border-radius: 8px;
  border: 1px solid #f99600;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  width: auto;
  display: inline-block;
  cursor: pointer;
}

.copyIcon {
  background: url("./assets/copy-icon.png") left top no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: 100%;
  text-indent: -9999px;
  cursor: pointer;
}
.copyIcon:hover {
  // background-position: left -24px;
}
.viewIcon {
  background: url("./assets/view-icon.png") left top no-repeat;
  width: 32px;
  height: 20px;
  display: inline-block;
  background-size: 100%;
  text-indent: -9999px;
  cursor: pointer;
}
.viewIcon:hover {
  background-position: left -24px;
}
.chatIcon {
  background: url("./assets/chat-50.png") left top no-repeat;
  width: 18px;
  height: 20px;
  display: inline-block;
  background-size: 100%;
  text-indent: -9999px;
  cursor: pointer;
}
.fillterIocn {
  background: url("./assets/fillter-icon.png") left top no-repeat;
  width: 21px;
  height: 21px;
  display: inline-block;
  background-size: 100%;
  text-indent: -9999px;
  cursor: pointer;
  position: absolute;
  margin-left: 10px;
}
.fillterIocn:hover {
  opacity: 0.8;
}

/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #2c3e50;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.costumSelect {
  position: relative;
  display: flex;
  overflow: hidden;
  color: #000;
}
select {
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
}

/* Arrow */
.costumSelect::after {
  content: "";
  background: url("./assets/chevron@2x.png") left center no-repeat;
  position: absolute;
  top: 12px;
  right: 10px;
  padding: 0 1em;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
/* Transition */
.costumSelect:hover::after {
  color: #f39c12;
}
.ml-auto {
  margin-left: auto !important;
}

.ant-table-filter-dropdown input[type="checkbox"]:checked,
.ant-table-filter-dropdown input[type="checkbox"]:not(:checked) {
  position: absolute;
  left: 0;
}

.ant-table-filter-trigger{
  font-size: 18px !important;
  color: #0d0c0c !important;
}

input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
input[type="checkbox"]:checked + label,
input[type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  line-height: 36px;
  display: inline-block;
  color: #666;
}
input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: -18px;
  width: 20px;
  height: 20px;
  /* border: 1px solid #ddd; */
  border-radius: 0;
  background: #fff;
  border: solid 2px #ddd;
}
input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
  content: "";
  width: 20px;
  height: 20px;
  background: url("./assets/checkbox.svg") -5px -6px no-repeat;
  position: absolute;
  background-size: 32px;
  top: -16px;
  left: 2px;
  /* border-radius: 100%; */
  transition: all 0.2s ease;
  overflow: hidden;
}
input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
input[type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.radioBtn {
  margin-right: 20px;
}

.tableGrid {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  border-collapse: collapse;
  width: 100%;
}

.tableGrid td,
.tableGrid th {
  border: 1px solid #ddd;
  padding: 8px;
}

.tableGrid tr:nth-child(even) {
  background-color: #f8f8f8;
}

.tableGrid tr:hover {
  background-color: #ddd;
}

.tableGrid th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e6e9f4;
  color: #000;
}
.justify-content {
  justify-content: space-between;
}
.inviteBlock {
  display: flex;
}
.addBtn {
  margin: 0 0 0 12px;
  line-height: 31px;
  width: 100px;
}
.emails {
  padding: 20px 0 0 0;
  color: #999;
}

// some common css

.t-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.f-col {
  flex-direction: column;
}

.f-row {
  flex-direction: row;
}

.j-space {
  justify-content: space-between;
}

.j-center {
  justify-content: center;
}

.m-auto {
  margin: auto;
}

.container,
.container-flud {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.confirmbtn {
  width: 80px;
  margin-left: 10px;
  border-radius: 15px;
}
.ant-btn {
  max-width: unset !important;
}
.ant-table-root, .card-layout {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.card-layout .form-btn {
  margin: 40px auto 25px;
  display: block;
  border-radius: 8px !important;
}

.ant-custom-btn {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: block;
  color: #ffffff;
  margin: 15px auto;
  border-radius: 10px;
  background-color: #2B7776;
  border-color: #2B7776;
  padding: 4px 15px;
}

.table-inspection {
  .viewIcon {
    margin-left: 0;
  }
}
.inpection-report-card {
  button {
    margin-right: 10px;
    padding: 4px 10px 3px 10px;
    font-size: 14px;
    display: inline-block;
  }
  .ant-picker {
    width: 100%;
  }
  input, textarea {
    border-radius: 4px;
  }
}
.report_corrections {
  margin-bottom: 20px;
}

.category_row {
  >div {
    text-transform: capitalize;
    font-weight: bold;
  }
  margin-bottom: 10px;
}

.line_row {
  >div {
    text-transform: capitalize;
  }
}

.ant-modal.vault-modal {
  max-width: 600px;
  width: 85% !important;
}
.header-center {
  text-align: center;
}
.step-back {
  cursor: pointer;
  font-size: 18px;
}
.vault {
  width: 112px;
  height: 90px;
  text-align: center;
  position: relative;
  cursor: pointer;

  .vault-bg {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 112px;
    z-index: 0;
    color: #2B7776;
  }

  .vault-content {
    position: relative;
    color: white;
    z-index: 5;
    padding-top: 30px;
    .vault-text {
      margin: 0;
      color: white;
      padding: 5px 14px 0;
    }
    p {
      margin: 0;
      padding: 1px 14px 0;
    }
  }

}
.msgCard {
  .list-items {
    cursor: pointer;
    margin-bottom: 20px;

    .profilePic {
      display: flex;
      justify-content: center;
      align-self: center;
    
    }
    .dataContent {
      margin: 0 0 8px 0;
      .reply_outline {
        color: #f99600;
        margin-left: 2px;
      }
    }
    .dataContent.bold li{
      font-weight: bold;
    }
  }
  
} 

.msgModal {
  .ant-modal-title {
    text-align: center;
  }
  .ant-modal-content {
    background-color: #f3f6fb;
    border-radius: 10px;
  }
  .ant-modal-body {
    max-height: 90vh;
    overflow: auto;
  }
  .ant-modal-header {
    background-color: #f3f6fb;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #cecece;
  }
  textarea{
    border-radius: 3px;
  }
  .ant-form-item .ant-input-textarea-show-count::after{
    font-size: 10px;
  }
  .msgForm {
    border: 1px solid #fff;
    background-color: #fff;
    padding: 10px 10px 0;
    border-radius: 10px;
    >div {
      justify-content: center;
    }
  }
  .msgBorder{
    margin-bottom: 20px;
  }
  p{
    margin: 0;
  }
  .userMsg {
    justify-content: flex-end;
    align-items: baseline;
    >div:first-child {
      border: 1px solid #2B7776;
      padding: 10px 5px;
      background-color: #2B7776;
      border-radius: 10px;
      padding: 10px 11px;
      color: #fff;
      box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);
    }
    &.timeWrapper {
      margin-right: 42px;
    }
  }
  .rcvrMsg {
    align-items: baseline;
    >div:last-child {
      border: 1px solid #fff;
      background-color: #fff;
      border-radius: 10px;
      padding: 10px 11px;
      color: #000000;
      box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);

    }
    &.timeWrapper {
      margin-left: 42px;
    }
  }
  .timeWrapper {
    color: #00000040;
    font-size: 10px;
    margin-top: 3px;
  }
  .msgWrapper {
    >div:first-child {
      margin-right: 10px;
    }
  }
  .manage_form {
    .btn {
      margin: 0 0px 0 10px;
    }
  }
}

.msgAvatar {
  text-transform: uppercase;
}
.msgsPagination.ant-pagination {
  justify-content: flex-end;
  display: flex;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 0 !important;
}
.manage_form {
  align-self: end;
  padding-bottom: 25px;
  .ant-modal-title {
    text-align: center;
    font-size: 1.5em;
  }
  .btn {
    border-radius: 8px !important;
    padding-top: 3px;
    margin-left: 10px;
    &.ant-btn > span {
      display: block;
      margin-top: -4px;
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 85rem !important;
  }
  .container-flud {
    max-width: 75rem !important;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
  .container-flud {
    max-width: 600px !important;
  }
}

// xs
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  .container-flud {
    max-width: unset !important;
  }
}

@media (max-width: 768px) {
  .m-hide {
    display: none !important;
  }
  .m-visible {
    display: flex !important;
  }
  .mainContainer {
    max-width: 100% !important;
    padding: 21px 15px 20px 15px !important;
  }
  .innerContainer {
    margin: 0 auto;
  }
  .flexContainer >div:first-child {
    width: 42%;
 } 
}

@media (max-width: 992px) {
  .t-hide {
    display: none !important;
  }
  .t-visible {
    display: flex !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
  .container-flud {
    max-width: unset !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .container-flud {
    max-width: unset !important;
  }
  .mainContainer {
    max-width: 99%;
    padding: 20px 15px;
    .innerContainer {
      max-width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .mainContainer {
    max-width: 99% !important;
    padding: 20px 15px !important;
    .innerContainer {
      max-width: 100%;
      padding: 15px;
    }
  }
  .mainContainer.profileContainer {
    width: 99%;
    padding: 20px 15px !important;
  }
  .settingOuter {
    width: 99%;
    margin: 0 !important;
    padding: 15px !important;
  }
}
#printablediv {
  .ant-input[disabled], span.ant-select-selection-item-content {
    color: rgb(0 0 0) !important;
  }
}
@media print {
  body {
    visibility: hidden;
  }
  #pdf-button{
    visibility: hidden;
  }
  #printablediv {
    // visibility: visible;
    // position: absolute;
    // left: 0;
    // top: 0;
    margin-bottom: 0px !important;
  }
  // .inpection-report-card {
  //   button {
  //     margin-right: 10px;
  //     padding: 4px 10px 3px 10px;
  //     font-size: 14px;
  //     display: inline-block;
  //   }
  //   .ant-picker {
  //     width: 100%;
  //   }
  //   input, textarea {
  //     border-radius: 4px;
  //   }
  // }
  .report_corrections {
    margin-bottom: 20px;
  }
}
.ant-radio-disabled + span{
  color: #0d0c0c !important;
}
.fs-small{
  font-size: small;
}
.text-nowrap{
  text-wrap: nowrap;
}
@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
  .display_remarks{
    font-size: 8px;
  }
  .display_name{
    font-size: 8px;
  }
}

@primary-color: #57b8ff;