.downloadBtn {
  width: 205px;
  height: 50px;
  margin: 3px 8px 72.4px 301px;
  padding: 11.3px 31px 8.7px 29.2px;
  border-radius: 30px;
  background: #000;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
    right: 0;
    top: 32px;
}
.downloadBtn svg{width: 38px; height: 38px;}
.mainContainer{position: relative;}
.fileFormat{border-top: 0;}
.btn{max-width: auto;}
.textBlock .labelBLock{width: 35%;}
.list01 p{margin: 0;}
.fileFormat{padding-left: 0;}
.textBlock .labelBLock{font-size: 17px;}
.textBlock .contentBLock{font-size: 16px;}