.bid-receive-root {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;

  .disabled{
    cursor: none;
    pointer-events:none;
    opacity: 0.5;
  }

  .ant-card {
    // margin-top: 20px;
    margin: auto;
  }

 /* table {
    border-spacing: 0 5px;
    max-width: 80%;
    margin: auto;
  }

  .ant-table-thead tr th {
    font-size: 1rem;
    color: grey;
    background: none;
  }

  .ant-table-row {
    * {
      font-weight: 600;
    }

    td {
      background: #f5f6fa;
    }

    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }*/
}
.compareModal {
  height: 80vh;
}
.compareModal .ant-modal-content {
  background-color: #f4f7fc;
}

@primary-color: #57b8ff;