body{
  background-color: #f2f3f8!important;
}
.container{
    max-width: 98%;
    margin: 28px auto;
   
}
.container2{
    max-width: 98%;
    margin: 50px 80px 0px  80px;


}
.remove-whitespace {
  margin: 0;
  padding: 0;
  line-height: 30px;
  height: 25px;
}
.pt-8 {

  padding-top: 8px;

}
.p-8 {

  padding: 8px;

}
.m-5 {

  margin: 15px;

}
.pl-4 {

  padding-left: 4px;

}
.card-justify{
  justify-content: space-between;
}
.container2, .container-flud {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

}
.leftSidePanel{
  padding: 0 10px 0 10px;
}
.rightSidePanel{
    /* width: 45%;   */
        padding: 0 10px 0 0;
        /* border-radius: 6px;
        box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
        background-color: #ffffff; */
        /* margin: 0 0 0 20px; */
        background: none;
}
.htittle{
    width: 100%;  
        /* padding: 0; */
        /* border-radius: 6px;
        box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
        background-color: #ffffff; */
        /* margin: 0 0 0 20px; */
        /* background: none; */
}
.dot { 
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.card02{
  padding: 10px 15px;
    border-radius: 11px;
    padding: 22px 20px;
    width: 32%;
    /* margin: 3px 17px; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}
.card022{
  padding: 10px 15px;
    border-radius: 11px;
    padding: 22px 20px;
    width: 95%;
    height: 189px;
    /* margin: 3px 17px; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}
.text-white{
  color:#e6e9f4 !important;
}
.card03{
  padding: 10px 15px;
    border-radius: 11px;
    padding: 22px 20px;
    width: 32%;
    /* margin: 3px 17px; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #2b7776;
}
.card033{
  padding: 10px 15px;
    border-radius: 11px;
    padding: 22px 20px;
    width: 100%;
    /* margin: 3px 17px; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #2b7776;
}
.w-40{

    width: 35%;
    /* margin: 3px 17px; */

}
.rownew{

    width: 2000px !important;
  

}
.break {
  flex-basis: 100% !important;
  height: 0 !important;
}
.containern {
  display: flex !important;
  flex-wrap: wrap !important;
}
.card {
padding: 10px 15px;
    border-radius: 11px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
.cardnw {
padding: 10px 15px 47px 15px;
width:100%;
    border-radius: 11px;
    margin: 10px 76px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    
  }
  .card h2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    text-align: left;
    color: #3f3f41;
    position: relative;
    padding-bottom: 5px;
    margin: 0 0 15px 0;
  }
  .card h2:after {
    content: "";
    height: 3px;
    background: #3f3f41;
    width: 90%;
    position: absolute;
    top: 100%;
    left: 0;
    max-width: 100px;
  
  }
  .addTenderBtn{
    width: 100%;
    max-width: 225px;
    padding: 0;
    border-radius: 8px;
    background: #2B7776;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.14px;
    text-align: left;
    color: #ffffff;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin:  15px 0;
  }
  .addTenderBtn .btn-icon{
    background:  url("../../assets/plus-btn.png") left center no-repeat;
    display: inline-block;
    width: 29px;
    height: 29px;
    margin-right: 10px;
  }
  .viewAll {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: right;
    color: #2B7776;
    margin: 10px 0 0 0;
    cursor: pointer;
  }
  .dataBlock{}
  .dataBlock .dataTitle{
      display: flex;
  }
  .dataBlock .dataTitle li {
   
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5a607f;
  }
  .dataBlock .dataTitle li:nth-child(1){
    width: 35%;
  }
  .dataBlock .dataTitle li:nth-child(2){
    width: 20%;
  }
  .dataBlock .dataTitle li:nth-child(3){
    width: 20%;
  }
  .dataBlock .dataTitle li:nth-child(4){
    width: 25%;
  }
  .dataBlock .dataContent{
      display: flex;
        padding: 10px;
        border-radius: 8px;
        background-color: #f5f6fa;
        margin: 0 0 8px 0;
        align-items: center;
    
}
.dataBlock .dataContent li:nth-child(1){
    width: 35%;
}
.dataBlock .dataContent li:nth-child(2){
    width: 20%;
}
.dataBlock .dataContent li:nth-child(3){
    width: 20%;
}
.dataBlock .dataContent li:nth-child(4){
    width: 25%;
}
.dataBlock .dataContent li.published{color: #f99600;}
.dataBlock .dataContent .startIcon{
    display: inline-block;
    width: 15px;
    height: 18px;
    margin-right: 10px;
    background: url("../../assets/starts.png") left center no-repeat;
}
.dataBlock .dataContent li{
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    text-align: left;
    color: #131523;
}
.d-flex{display: flex;}
.mt-2{margin-top: 20px;}

.dataBlock .dataContent .editIcon {
    background: url("../../assets/edit-icon.png") left center no-repeat;
    width: 30px;
    height: 30px;
    display: block;
    background-size: 100%;
    text-indent: -9999px;
    cursor: pointer;
  }
  .list-items{
    display: flex;
    justify-content: space-between;
  }
  .list-items .dataContent{
      width: 92%;
      margin-bottom: 20px;
  }
  .list-items .profilePic{
    width: 40px;
    height: 40px;
    display: block;
    background-size: 100%;
    /* text-indent: -9999px; */
    cursor: pointer;
    border-radius: 100%;
  }
  .w-5{width:50% !important;}
  .w-3{width:33% !important;}
  .list-items .startIcon{margin-left: auto;}
  .dataBlock .dataContent li.draft{color: #f0142f;}
  .dataBlock .dataTitle.list2 li, .dataBlock .dataContent.list2 li{width: 33.3%;}
  .profilePic.circle{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background: #2B7776;
    font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
}
.profilePic.circle.circleOrange{
    background-color: #f99600;
}
.dataBlock .dataTitle.list2{
    border-bottom: solid 1px #e6e9f4;
    margin: 0 0 15px 0;
}
.paginationBlock{
    border-top:#e6e9f4 solid 1px;
    padding: 15px 0 0 20px;
}
.paginationBlock .pagination{
    display: flex;
    justify-content: flex-end;
}
.paginationBlock .pagination li{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #7e84a3;
    padding: 4px 0px;
    border-radius: 4px;
    width: 36px;
    margin: 0 2px;
    cursor: pointer;
}
.paginationBlock .pagination li:hover, .paginationBlock .pagination li.active{
    color: #fff;
    background-color: #2B7776;
}
.paginationBlock .pagination li.pagiLeftArrow{
    background: url("../../assets/left-arrow.png") center center no-repeat;
    width: 30px;
    height: 30px;
}
.paginationBlock .pagination li.pagiRightArrow{
    background: url("../../assets/right-arrow.png") center center no-repeat;
    width: 30px;
    height: 30px;
}
.dashboardTitle{
  align-items: center;display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.fillterIocn02{
  background: url("../../assets/filter.svg") left top no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: 100%;
  text-indent: -9999px;
  cursor: pointer;
  margin-left: 10px;
}
.fillterIocn02:hover{opacity: 0.8;}
.dashboardTitle{
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  text-align: left;
  color: #3f3f41;
}

.formGroup .inputFiled{
  width: 270px;
  height: 45px;
  border-radius: 5px;
  border: solid 0.5px #d5d7e3;
  background-color: #e6e9f4;
  padding: 5px 15px;
  font-family: Poppins;
  font-size: 16px;
  float: right;
  color: #3f3f41;
}
.float-right{

  float: right;

}
.formGroup input:focus{outline: 0;}
.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}
.formGroup label{
  font-family: Poppins;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.15;
  letter-spacing: normal;
  text-align: left;
  color: #3f3f41;
  padding: 0 30px 0 0;
  max-width: 250px;
  display: block;
  line-height: 19px;
  font-weight: 500;
  width: 50%;
}
.formGroup {

}
.formGroup .days{display: flex;}
.formGroup .days .inputFiled{width: 200px;}
.formGroup .days span{
  padding: 10px 12px;
}
.formGroup .textarea01{
height: 130px;
border-radius: 10px;
}
.searchBtn{
  width: 154px;
  height: 54px;
  margin-left:auto;
  padding: 8px 36px;
  border-radius: 27px;
  background-color: #2B7776;
  font-family: Poppins;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}
.searchBtn:hover{
  opacity: 0.8;
}