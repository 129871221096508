.__CompareBids__ {
	margin: 30px auto;
	width: 50vh;
	min-width: 700px;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .commentblock {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
  }
  .formGroup {
   	justify-content: start;
  }
  .comments {
    width: 70%;
  }
}

.title {
	text-align: center;
	margin-bottom: 30px;
}

.__Compare__Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bid-compare-root .ant-table{
	background-color: #f4f7fc;
}

.bid-compare-root table {
	width: auto;
  min-width: unset !important;
  margin: auto;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;

}

.bid-compare-inner table{
 padding-left: 30px;	
}
.bid-compare-root .ant-table-expanded-row-fixed {
    overflow: visible !important;
    width: 100% !important;
    padding: 16px 0;
    margin: -16px 0;
}
.bid-compare-root .ant-table-cell {
	padding-right: 0;
}
.compare_btn {
	width: auto;
	display: table;
	margin: 0 auto;
}

@primary-color: #57b8ff;