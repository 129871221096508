.__TenderCard__ {
  margin-bottom: 10px;
  .err {
    width: 100%;
    text-align: center;
  }

  .cardcontainer {
    li {
      width: 25%;
      padding: 0 5px;
      word-break: break-all;
    }
    .title {
      display: flex;

      li {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: normal;
        text-align: left;
        color: #5a607f;
      }
    }
    .content {
      display: flex;
      padding: 10px;
      border-radius: 8px;
      background-color: #f5f6fa;
      margin: 0 0 8px 0;
      align-items: center;
    }
    .starIcon {
      display: inline-block;
      width: 15px;
      height: 18px;
      margin-right: 10px;
      background: url("../../assets/starts.png") left center no-repeat;
    }
  }
}

@primary-color: #57b8ff;