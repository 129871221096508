.profile {
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 1em;
}
.profileContainer {
    width: 80%;
}

.profileContainer h3 {
    text-transform: capitalize;
    /* margin-bottom: 20px; */
    margin-top:10px;
}
.address p {
    margin: 0;  
}
.profileContainer .ant-upload.ant-upload-select-picture-card {
    width: 150px;
    height: 150px;
    display: block;
    border-radius: 75px;
    margin: 0 auto 30px;
}

.profileContainer .ant-upload.ant-upload-select-picture-card .image {
    height: 150px;
    width: 150px;
    overflow: hidden;
    border-radius: 75px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.fixed_height{
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}
.fixed_height tbody{
  display:block;
  overflow:auto;
  height:212px;
  width:100%;
}
.fixed_height thead tr{
  display:block;
}

.fixed_height th:first-child, .fixed_height td:first-child {
  text-align: left;
  width: 90%;
}

.fixed_height th:last-child, .fixed_height td:last-child {
  text-align: left;
  width: 80px;
}
.btn{
  background-color:#2B7776;
  color: white;
}