.width-70{
    width: 70% !important;
}
.width-15{
    text-align: center;
    width: 15% !important;
}
.settingsIcon {
	    margin-left: 0px;
}
.rowValue {
	text-transform: capitalize;
}
