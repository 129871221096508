.__ClosingDate__ .dayslabel {
  margin: 0 10px;
}
.__ClosingDate__ .formGroup {
  justify-content: flex-start;
}
.parentBlock2.parentBlock3 {
  max-width: 50%;
  margin: 0 auto;
}
.parentBlock2 label {
  width: 50%;
}
.parentBlock2 .formGroup {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}
.parentBlock2 .formGroup span {
  padding-left: 10px;
}
.formGroup .textarea01 {
  height: 150px;
  margin: 0;
  width: 50%;
  padding: 15px;
}
.formFiled {
  width: 50%;
  height: 43px;
  border-radius: 9px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  padding: 15px;
}
.formFiled.days {
  width: 85px;
}
.selectDate {
  position: relative;
}
.selectDate .calendraIcon {
  background: url("../../../assets/calendar.svg") left center no-repeat;
  position: absolute;
  right: 10px;
  top: 7px;
  width: 25px;
  height: 25px;
  background-size: 100%;
}
.selectDate .formFiled {
  width: 100%;
}

