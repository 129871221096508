.settingOuter {
  margin: 40px 70px 40px 70px;
  padding: 21px 0 20px;
  border-radius: 5px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.tabsBlock {
  display: flex;
  justify-content: space-around;
  border-bottom: solid 2px #b3b4b9;
  padding: 0 0 0px 0;
}
.tabsBlock li a{
  text-decoration: none;
}
.tabsBlock li{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.07px;
  text-align: left;
  color: #d5d7e3;
  padding-bottom: 20px;
}
.tabsBlock li:hover {
  color: #2B7776;
  cursor: pointer;
}
.tabsBlock li.active {
  color: #2B7776;
  font-weight: 600;
  position: relative;
}
.tabsBlock li.active:after {
  content: "";
  height: 3px;
  background: #2B7776;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}
.tabContainer h2 {
  font-family: Poppins;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: 0.09px;
  text-align: center;
  color: #3f3f41;
  position: relative;
  padding-bottom: 5px;
}
.tabContainer h2:after {
  content: "";
  height: 3px;
  background: #3f3f41;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 50%;
  max-width: 100px;
  transform: translateX(-50%);
}
.tabContentBlock {
  max-width: 1100px;
  margin: 14px auto 15px auto;
  padding: 16px 59px 13px 60px;
  object-fit: contain;
  border-radius: 6px;
  background-color: #f2f3f8;
}
.titleBlock {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: left;
  color: #5a607f;
  display: flex;
  justify-content: space-between;
}
.titleBlock .title1 {
  width: 50%;
}
.titleBlock .title2 {
  width: 25%;
  padding-left: 26px;
}
.titleBlock .title3 {
  width: 25%;
  text-align: right;
}
.titleBlock .title1,
.titleBlock .title2,
.titleBlock .title3 {
  padding: 15px 20px;
}
.listing li {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;

  align-items: center;
}
.listing li .editIcon {
  background: url("../../assets/edit-icon.png") left center no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;
  background-size: 100%;
  text-indent: -9999px;
  cursor: pointer;
  margin-left: 25px;
}
.listing li .deleteIcon {
  background: url("../../assets/delete-icon.png") left center no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: 100%;
  text-indent: -9999px;
  cursor: pointer;
  margin-left: 25px;
}
.button1 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.08px;
  text-align: left;
  color: #ffffff;
  max-width: 148px;
  /* height: 29px; */
  margin: 15px 0 0 auto;
  padding: 2px 18px 2px 17px;
  border-radius: 8px;
  background-color: #2B7776;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.button1:hover,
.listing li .deleteIcon:hover,
.listing li .editIcon:hover {
  opacity: 0.8;
}
.form-label{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  width: 100px;
  display: inline-block;
  width: 25%;
  margin-bottom: 20px;
}
.form-group{
  display: flex;
  align-items: center;
}
.form-control {
  width: 75%;
  height: 45px;
  margin: 0px 0 20px 0;
  border-radius: 31px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  padding: 0 20px;
  font-size: 18px;
}
.form-control:focus{outline: 0;}
.formSettings{
  max-width: 655px;
  margin: 0 auto;
  padding: 30px 0;
}

  .viewIcon{
    background: url("../../assets/view-icon.png") left top no-repeat;
    width: 32px;
    height: 20px;
    display: block;
    background-size: 100%;
    text-indent: -9999px;
    cursor: pointer;
    margin-left: 30px;
  }
  .viewIcon:hover{
    background-position: left -24px;
  }
  .text-center{text-align: center;}
  /* .title1.view01{
    padding: 15px 0 0 48px;
  } */
  .button2{max-width: 190px;}

  @media (max-width: 1400px) {
    .tabsBlock li {
      font-size: 14px;
    }
  }