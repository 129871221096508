.parentBlock2.parentBlock3{
  max-width: 50%;
  margin: 0 auto;
}
.parentBlock2 label{ width: 50%;}
.parentBlock2 .formGroup{     align-items: center;display: flex;    margin-bottom: 20px;}
.parentBlock2 .formGroup span{padding-left: 10px;}
.formGroup .textarea01{
  height: 150px;
  margin: 0;
  width: 50%;
  padding: 15px;
}
.formFiled{
  width: 50%;
  height: 43px;
  border-radius: 9px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  padding: 15px;
}
.formFiled.days{width: 85px;}
.selectDate{
  position: relative;
}
.selectDate .calendraIcon{
  background: url("../../../assets/calendar.svg") left center no-repeat;
  position: absolute;
  right: 10px;
  top: 7px;
  width: 25px;
  height: 25px;
  background-size: 100%;
}
.selectDate .formFiled{width: 100%;}

input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 45px;
    cursor: pointer;
    line-height: 36px;
    display: inline-block;
    color: #666;
}
input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    /* border: 1px solid #ddd; */
    border-radius: 100%;
    background: url("../../../assets/checkbox-unchecked.svg") left top no-repeat;
}
input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 32px;
    height: 32px;
    background: url("../../../assets/checkbox.svg") left top no-repeat;
    position: absolute;
    background-size: 100%;
    top: 0;
    left: 0;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
input[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.radioBtn{margin-right: 20px;}
h4{margin: 0; padding: 0;}
.inputFiled{
  width: 270px;
  height: 45px;
  border-radius: 45px;
  border: solid 0.5px #d5d7e3;
  background-color: #e6e9f4;
  padding: 5px 15px;
  font-family: Poppins;
  font-size: 16px;
  float: right;
}
select{color:#000;}