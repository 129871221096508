.headerBlock{display: flex; padding: 10px;margin-bottom: 10px;}
.headerBlock li{
    font-family: Poppins;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    text-align: left;
    color: #5a607f;
    position: relative;
}
.headerBlock li:nth-child(1){width: 70%;}
.headerBlock li:nth-child(2){width: 10%; text-align: center;}
.headerBlock li:nth-child(3){width: 10%; text-align: center;}
.headerBlock li:nth-child(4){width: 10%; text-align: center;}
.contentList{display: flex; padding: 10px; background: #fff; margin: 0 0 20px 0;}
.contentList li{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: left;
    color: #131523;
    position: relative;
}
.contentList li:nth-child(1){width: 70%;}
.contentList li:nth-child(2){width: 10%; text-align: center;}
.contentList li:nth-child(3){width: 10%; text-align: center;}
.contentList li:nth-child(4){width: 10%; text-align: center;}
.innerContainer .viewIcon{margin: 0px;display: inline-block;}
.innerContainer .deleteIcon{margin-left: 0;}
