.profile {
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 1em;
}
.profileContainer {
    width: 80%;
}

.profileContainer h3 {
    text-transform: capitalize;
    /* margin-bottom: 20px; */
    margin-top:10px;
}
.address p {
    margin: 0;  
}
.profileContainer .ant-upload.ant-upload-select-picture-card {
    width: 150px;
    height: 150px;
    display: block;
    border-radius: 75px;
    margin: 0 auto 30px;
}

.profileContainer .ant-upload.ant-upload-select-picture-card .image {
    height: 150px;
    width: 150px;
    overflow: hidden;
    border-radius: 75px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.ant-tabs-tab-btn {
    color: #2B7776;
    text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2B7776 !important;
    text-shadow: 0 0 0.25px currentColor;
}
.ant-btn:not([disabled]):hover {
    text-decoration: none;
    background-color:#2B7776 !important;
    color: white;
}