.parentBlock2 {
  max-width: 85%;
  margin: 0 auto;
}
.parentBlock2 label {
  width: 20%;
}
.parentBlock2 .formGroup {
  display: flex;
  margin-bottom: 20px;
}
.formGroup .textarea01 {
  height: 150px;
  margin: 0;
  width: 80%;
  padding: 15px;
}
.formFiled {
  width: 80%;
  height: 43px;
  border-radius: 9px;
  border: solid 1px #d5d7e3;
  background-color: #ffffff;
  padding: 15px;
}
.summaryHeadder {
  padding: 10px 65px;
  box-shadow: 0 0.5px 2px 0 rgb(21 34 50 / 8%);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.summaryHeadder h2 {
  font-family: Poppins;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: 0.09px;
  text-align: center;
  color: #3f3f41;
  position: relative;
  padding-bottom: 5px;
}
.summaryHeadder h2:after {
  content: "";
  height: 3px;
  background: #3f3f41;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 50%;
  max-width: 100px;
  transform: translateX(-50%);
}
.topLeftInfo {
  display: flex;
  width: 335px;
  height: 48px;
  margin: 4px 426px 24px 0;
  padding: 12.7px 14.8px 14px 12.8px;
  border-radius: 8px;
  background-color: #f5f6fa;
}
.topLeftInfo svg {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.topLeftInfo span {
  font-family: Poppins;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9c9c9c;
}
.mainContainer {
  padding: 21px 60px 20px 60px;
  margin: 15px auto 15px auto;
  max-width: 80%;
}
.block01 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.edit-icon {
  cursor: pointer;
}
.edit-icon svg {
  width: 35px;
}
.edit-icon:hover {
  opacity: 0.7;
}
.textBlock {
  display: flex;
  margin-bottom: 20px;
}
.textBlock .labelBLock {
  width: 20%;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3f3f41;
}
.textBlock .contentBLock {
  width: 45%;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9c9c9c;
}
.titleSummary {
  display: flex;
  align-items: center;
}
.titleSummary .titile {
  width: 95%;
  text-align: center;
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.titleSummary .edit-icon {
  width: 5%;
}
.list01 p {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3f3f41;
}
.list01 ul {
  margin: 0 0 0 20px;
}
.list01 li {
  position: relative;
  padding-left: 15px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3f3f41;
}
.list01 li:after {
  width: 7px;
  height: 7px;
  background-color: #000000;
  content: "";
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  position: absolute;
  left: 0;
  top: 9px;
}
.bdrb {
  background: #707070;
  height: 1px;
  margin: 20px 0 0 0;
}
.buttonBlock {
  margin: 0 10%;
}
.filesNames {
  border-radius: 10px;
  border: solid 1px #9c9c9c;
}
.filesNames .filesDes {
  padding: 20px;
}

.filesNames .filesDes span:first-child {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3f3f41;
}
.filesNames .filesDes span:nth-child(2) {
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3f3f41;
  background-color: #f4f7fc;
  margin-left: 15px;
  padding: 8px;
}
.fileFormat {
  display: flex;
  align-items: center;
  border-top: solid 1px #cacaca;
  padding: 10px 20px;
}
.fileFormat .fileType {
}
.fileFormat .fileInfo {
  margin: 0px 0 0 6px;
}
.fileFormat svg {
  width: 25px;
  height: 25px;
}
.fileFormat .fileInfo .text {
  font-family: Poppins;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3f3f41;
}
.fileFormat .fileSize {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #bfc5e2;
}
.font-size-18 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #9c9c9c;
}
.summaryOuter {
  margin-bottom: 20px;
}
